import React from 'react';

const Modal = ({ isOpen, onClose, largeContent, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-300 bg-opacity-50 flex justify-center items-center z-50" onClick={onClose}>
      <div className={(largeContent) ? "bg-white p-6 rounded-lg shadow-lg relative max-h-[70vh] overflow-y-auto" : "bg-white p-6 rounded-lg shadow-lg"} onClick={(e) => e.stopPropagation()}>
        <button className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" onClick={onClose}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        {children}
      </div>
      </div>
  );
};

export default Modal;