import React, { useEffect, useState } from 'react';
import {useNavigate} from 'react-router-dom'
import TablatureDisplay from '../components/TablatureDisplay';

const Landing = () => {
    const navigate = useNavigate()

    const tab = [["","",12,10,14,12],["","",12,10,14,12],["","",12,10,14,12],["","",12,10,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,9,12,10],["","",10,9,12,10],["","",9,9,11,10],["","",9,9,11,10],["","",9,7,10,8],["","",9,7,10,8],["","",9,7,10,8],["","",9,7,10,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,8,8],["","",7,7,8,8],["","",7,5,8,7],["","",7,5,8,7],["","",7,5,7,7],["","",6,5,7,5],["","",5,3,5,5],["","",4,3,5,3], ["","",12,10,14,12],["","",12,10,14,12],["","",12,10,14,12],["","",12,10,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",13,12,14,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,10,12,12],["","",10,9,12,10],["","",10,9,12,10],["","",9,9,11,10],["","",9,9,11,10],["","",9,7,10,8],["","",9,7,10,8],["","",9,7,10,8],["","",9,7,10,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,9,8],["","",7,7,8,8],["","",7,7,8,8],["","",7,5,8,7],["","",7,5,8,7],["","",7,5,7,7],["","",6,5,7,5],["","",5,3,5,5],["","",4,3,5,3]]
    const chords = ["Cmaj7","","","","E7","","","","Fmaj7","","","","Bmin7b5","","E7","","Amin7","","","","D7","","","","Dmin7","","G7","","Emin7","A7","Dmin7","G7", "Cmaj7","","","","E7","","","","Fmaj7","","","","Bmin7b5","","E7","","Amin7","","","","D7","","","","Dmin7","","G7","","Emin7","A7","Dmin7","G7"]

    return (
        <div className="relative min-h-screen flex items-center justify-center w-full">
            <TablatureDisplay notes={tab} bpmeas={4} chords={chords} tick={-11} />
            <div className="absolute bg-gray-50 bg-opacity-80 top-0 left-0 w-full h-full flex items-center justify-center">
            <div className="bg-white shadow-lg rounded-lg p-12 my-4 text-center w-[80%] mt-8 lg:mt-0">
                <h1 className="text-3xl lg:text-5xl font-medium pb-10 lg:p-10">Unlock Your Guitar Voicing Potential</h1>
                <div className="flex justify-center">
                    <p className="text-xl lg:text-2xl lg:w-1/2">Explore Smart Voicing Tablature for Any Chord Progression, Powered by Cutting-Edge AI</p>
                </div>
                <div className="flex justify-center">
                    <div className="lg:w-7/12 flex justify-center pt-10">
                        <div className="lg:w-1/2 px-10">
                            <div className="bg-orange-500 rounded-lg hover:opacity-70 cursor-pointer flex justify-center items-center" onClick={() => navigate('/player')}>
                                <p className="py-2 px-4 font-medium text-xl lg:text-4xl text-white">Play Now</p>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            </div>
      </div>
    )
}
    
export default Landing