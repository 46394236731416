import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Player from './screens/Player';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Banner from './components/Banner'
import MobileBanner from './components/MobileBanner'
import ChordProgress from './screens/ChordProgress';
import Landing from './screens/Landing'
import Export from './screens/Export'
import Login from './screens/Login'
import SignUp from './screens/SignUp'
import EmailVerify from './screens/EmailVerify'
import SendPasswordReset from './screens/SendPasswordReset'
import PasswordReset from './screens/PasswordReset'
import About from './screens/About'

const BannerWrapper = () => {
  // Wrap the banner in a component so we can get the current pathname
  const location = useLocation()

  return (
    <div>
      { (location.pathname === '/export' 
      || location.pathname === '/login' 
      || location.pathname === '/signup' 
      || location.pathname === '/verify' 
      || location.pathname === '/request-password-reset'
      || location.pathname === '/password-reset') ?
        null :
        <div>
          <div className="hidden lg:flex">
            <Banner />
          </div>
          <div className="lg:hidden">
            <MobileBanner />
          </div>
        </div>
      }
    </div>
  )
}

ReactDOM.render(
  <Router>
    <BannerWrapper />
    <Routes>
      <Route exact path="/" element={<Landing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/verify" element={<EmailVerify />} />
      <Route path="/request-password-reset" element={<SendPasswordReset />} />
      <Route path="/password-reset" element={<PasswordReset />} />
      <Route path="/player" element={<Player />} />
      <Route path="/progress" element={<ChordProgress />} />
      <Route path="/export" element={<Export />} />
      <Route path="/blog" element={<About />} />
    </Routes>
  </Router>,
  
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
