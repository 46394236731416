"use client"

import BarDisplay from "./BarDisplay"

const TablatureDisplay = ({notes, bpmeas, chords, tick}) => {
  return (
    <div className={(chords.length / bpmeas > 3) ? "flex flex-wrap" : "flex flex-wrap justify-center"}>
      {Array(chords.length / bpmeas).fill('').map((_, index) => (
        <div
          key={index}
          className="w-1/4 py-2 lg:py-4"
          style={{ maxWidth: '25%' }}
        >
          <BarDisplay 
            parentIndex={index}
            bpmeas={bpmeas} 
            notes={notes.slice(index * bpmeas, (index + 1) * bpmeas)} 
            chords={chords.slice(index * bpmeas, (index + 1) * bpmeas)}
            tick={tick}
          /> 
        </div>
      ))}
    </div>
  )
}

export default TablatureDisplay
