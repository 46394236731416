import { useState } from "react"
import Button from "../components/Button"
import TextInput from "../components/TextInput"
import { sendEmail } from "../api/api"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const About = () => {

    const [name, setName] = useState('')
    const [message, setMessage] = useState('')

    const contact = async () => {
        const toastID = toast.loading('Sending Message...', {
            position: 'top-center'
        })
        try {
            const email = localStorage.getItem('email')
            const subject = 'User ' + name + ' has a comment'
            const fullMessage = message + ' from ' + email
            await sendEmail(subject, fullMessage)
            toast.dismiss(toastID)
            toast.success('Message sent', {
                type: 'success',
                position: 'top-center',
                autoClose: 4000
            })
        } catch (e) {
            toast.dismiss(toastID)
            toast.error('Error sending message', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }
    }

    const posts = [
        {
            title: 'How to use VoicingVault',
            date: '8/19/2024',
            body: [
                'VoicingVault is a website that gives guitarists tablature suggestions when prompted with chord progressions. The website has three main features: the player, the progress tracker, and the blog. I will walk through the use cases for each feature.',
                'The player is VoicingVault’s main feature. When you open the player, you will see one measure of music in a 4/4 time signature with quarter note markings. You can add a measure by pressing the + icon to the right of the measure, which can add as many measures as you would like (just one at a time). Each measure allows for chord name input above each quarter note dash. You can input a chord name by pressing the input fields above the dashes and typing the chord names with your keyboard. Once you enter editing mode on any of these input fields, a suggestion box will pop up with all the available chord names, filtered on the part of the chord name you have inputted.',
                'The player has various buttons on the top of the section, corresponding to settings, help, and search. You can adjust the time signature to be any quarter note time signature by pressing the settings (gear) icon at the top right. For example, change this field to three beats per measure if you want chord tablature suggestions for a waltz. This settings tab also has search constraints. You can limit the outputted strings by unchecking the string with the standard tuning root position. You can create a similar limit for the fret range by selecting a minimum and maximum fret. So, if you want to practice the top four string voicings between the 6th and 12th fret, you can uncheck the ‘E’ and ‘A’ strings, set your minimum fret to 6, and your maximum fret to 12. The help button will give a summarized version of these instructions. The search button will allow you to select predefined songs from a library. Clicking on a song name will load those chords into the player input.',
                'Clicking generate on the player will generate tablature for your chord input. This tablature will show below the player, with a play and a print button at the bottom of the screen. Pressing print will open a new tab on your web browser with a full-screen display for the tablature and automatically open the browser print message, for you to practice with a physical copy. The play button will open up a guitar neck view showing the first chord in the tablature sequence. Pressing the play icon on the right side will start a metronome in sync with the tablature. Play along with the tablature the whole way through to save the voicings to the progress tab, so long as you have an account.',
                'The progress tracker is only available to users that create an account. It’s free to sign up, just click the ‘Create Account’ button in the top right. Every voiced chord progression you have practiced with the website will be in the progress tracker. These are ordered chronologically and shown with a guitar neck visualization. Visit this tab to practice a voicing or chord progression again.',
                'Finally, the blog is where you are now. I write these articles to share my thoughts on guitar, chord theory, practicing, and bringing them together with computer science. You’ll find a ‘Contact Us’ section at the bottom of this page. We would love to hear from you, so please reach out with any thoughts.'
            ]
        },
        {
            title: 'Remember to Play Music With People',
            date: '8/5/2024',
            body: [
                'VoicingVault is a solo practice site. You can input chord progressions and receive tablature to play the chords with good voice leading. All the features, including the play-along and the chord progress tracker, are aimed at a single user. It’s a great way to practice your chord voicings and you will never run out of exercises. However, always remember that jazz (and music in general) is a social activity.',
                'Playing with other people is also important for playing a song correctly. Chord inversions are a key part of how VoicingVault operates. Even if a song asks for an Amin7 chord, you can always play the second inversion with an E in the root for an Emin7b5 chord. Those two chords are the same so long as someone else plays an A note in a lower register. That person could be a bassist, a piano player, or anyone with a low register, but they need to hold down the chord root. So, you need another player to keep all your creative chord inversions faithful to the song.',
                'Other people flesh out the song and open up more roles for the guitarist. The rhythm will sound great with a drummer and a bassist. The melody will be full with a saxophonist, a pianist, or a trumpet player. These roles are all important to fill to make a song swing. Not to mention, those other players give the guitarist flexibility to assume more roles. Now you can take a solo spot because other folks are laying down the chords. Or, take a chorus of melody - your horns can play backgrounds to give the melody even more character. Great chord voicings and comping should be an important part of a guitarist’s role, not the guitarist\'s only job. Playing with others is the best way to do everything with the guitar.',
                'I should also mention that I like playing in a group mainly because it’s fun. Music should be enjoyable to play, and one of my favorite parts is the free-flowing creativity that comes with a jam session or group performance. Someone could play a melody with slight embellishments that inspire another musician’s entire solo, a rhythm that comes from a drummer could propagate through all the other musicians, or a positive remark from another musician could reaffirm that a phrase sounded great. This interplay always encourages me to keep practicing guitar, so always be on the lookout for the element of music that excites you.',
                'How can VoicingVault help you play better with a group? The primary way that VoicingVault can help is by keeping your vocabulary sharp. Let\'s say you show up to a jam session and somebody calls out a song you’ve been practicing on VoicingVault. You can use multiple voicings for each chord and know that those voicings flow nicely. VoicingVault can also help in the other case - a song you haven’t practiced in a while. You get your hands on a lead sheet and see a chord transition you’re not used to. You can plug those chords into VoicingVault, and get tablature that voices those tricky chords well. Whether it’s a song you know well or a new song, VoicingVault can help you play better in a group.'
            ]
        },
        {
            title: 'How can a computer generate guitar chords?',
            date: '7/29/2024',
            body: [
                'VoicingVault helps users practice by generating tablature for entire chord progressions. Before calculating the tablature for a chord progression, we need to know the tablature for a chord’s voicings. We explored a few methods for obtaining tablature for different chord voicings before choosing one to build VoicingVault, which I’ll go through below.',
                'Before I talk about generation, I’ll talk about what it means for a computer to know the tablature for a chord’s voicings. We store all chord data inside arrays in memory, which are fixed-length data structures that we can configure to store six integer values. In our notation, a non-negative number corresponds to a fret number (starting at 0 for the open string), and a -1 corresponds to a muted string. Therefore, if we want to codify an Amin7 voicing we can store one voicing as [-1, -1, 5, 5, 5, 5], which would be the low E and A strings muted with the fifth fret played on the D, G, B, and high e strings. We chose this representation because it is unambiguous (no two distinct chord voicings could be represented with the same array) and easy to store in memory (we can serialize an array into a string for database storage).',
                'Now that we have a representation of a chord voicing in a computer, how can we generate voicings for a chord? The first approach we took (which we didn’t keep, but was good to experiment with) was generating the chords off intervals. Every chord is defined as a list of intervals from the root note, sometimes called stacking thirds. For example, a min7 chord is formed by starting with a root note then traveling up a minor third, a major third, and then another minor third. We also have the constraint that a hand can usually span the width of around five frets comfortably (this can vary, of course, but any person will have a maximum width). Therefore, we can find many voicings for a chord with the following strategy: loop over all possible root note positions on the neck and select the remaining notes by choosing a valid note in the chord for each subsequent string. For those familiar with computer algorithms, this algorithm is a recursive backtracking algorithm similar to calculating permutations or solving sudoku. This method can generate tons of voicings for each chord, and we may use it in a future VoicingVault feature, but ultimately we needed more control over the voicings for our current version.',
                'Our second approach was worse for generating chord voicings but used cool deep-learning technology, and has the potential to work with the best performance in the future. In this approach, we trained a long short-term memory (LSTM) model to generate the tablature sequences given the chord names as input. For example, you could input the sequence ‘Amin7, D7, G7’ and it would give out tablature to voice those three chords as a progression. LSTM models specialize in learning sequences because they can learn the context between items in a list, like a list of chords or tablature. Because this was deep learning, we never had to explicitly instruct the model on generating chord tablature. Therefore, the model could output chord tablature, but sometimes it could make an error in the voicing because it’s a deep-learning model with no hard-coded truth.',
                'The approach we currently use in VoicingVault is to hardcode the chord voicings. We support seven chord types: maj, min, maj7, min7, 7, min7b5, and dim7 (with more to come soon!). There are a ton of playable triads or drop2 chord voicings, so we only hardcode the C shapes (Cmaj, Cmaj7, …). Then, we can transpose those shapes to fit any given chord root. This solution takes the most manual labor but gives us the highest control over the quality of our model. We can guarantee that each chord VoicingVault outputs is a shape we recommend for a great total chord progression tablature.'
            ]
        },
        {
            title: 'Why are we different from other guitar sites?',
            date: '7/23/2024',
            body: [
                'There are lots of sites that teach people how to play guitar. The big players that I’m aware of are UltimateGuitar, IRealPro, Chordify, and, of course, VoicingVault. These websites have chord (and sometimes melody) information for any well-known song, and options to add more song data if applicable. Each has its respective niche, which I’ll summarize in the next section.',
                'UltimateGuitar has a massive library of songs, each inputted by users. Their approach to chord notation is to annotate the song lyrics with chord names and supply chord diagrams at the beginning. Therefore, if I wanted to play a song with an F7, I could scroll through some F7 voicings for reference before following the lyrics. Sometimes they supply tablature, but that needs to be manually inputted by a user. Chordify typically supplies one tablature voicing for a chord. They collect chord information differently than UltimateGuitar by identifying chords from audio data. Finally, IRealPro has each chord chart stored in its suite of products, with a tool for adding more charts. Their primary use is to supply backing audio for solo practice but they also have a guitar tablature feature. Their tablature feature is similar to UltimateGuitar, with a few options that you can click through for reference. However, These voicings have no inter-chordal voice-leading relationships.',
                'How is VoicingVault different? It’s all in how we recommend tablature for a sequence of chords. These older tools fall short because they either recommend one voicing per chord or voicings in no particular order without following voice-leading principles. VoicingVault has all drop2 chord voicings (and more to come!), generates tablature with effective voice leading, and generates effective tablature for any chord progression. Plus, that’s just our core feature! We also have a tablature printer, a play-along feature, and a progress report with all chord voicings you’ve practiced before.',
                'Those other chord sites can’t compete with our patent pending tablature generation models. Of course, they have larger song libraries to get your chord progressions (for now). So, I recommend you take those chord progressions to VoicingVault to get the best tablature recommendations available. Please let our team know if you have any difficulties importing chord progressions, or if you have any suggestions on how we could improve the process.'
            ]
        },
        {
            title: 'What does good voice leading mean?',
            date: '7/15/2024',
            body: [
                'In my last post, I said that guitarists can find the best choices for chord voices in a progression by following voice-leading rules. I added that I like to follow some main rules: maintaining common tones between chords and moving voices that must move in small intervals. I’d like to flesh out my approach to voice leading more here.',
                'A common tone between chords is a single note that stays the same between any two chords. For guitar, this is typically further constrained to being the same fret and string position for any note. Consider the example of moving from a Cmin7 to an F7 chord. Cmin7 has the notes C, Eb, G, and Bb and F7 has the notes F, A, C, and Eb. Therefore, it is possible (and preferred) to play the Eb and C notes in the same position in both chords. When these tones are not shared, such as the G and Bb in the Cmin7 and the F and A in the F7, the other rule for moving in small intervals takes effect. Following this rule could mean moving the G to an F a major second down and the Bb to an A a minor second down because that will minimize the intervals.',
                'These voice-leading rules help meaningfully voice chord progressions in a way that emphasizes melodic movement between chords and fits the jazz style. Those two main rules might seem like a massive simplification; because they are. I can simplify voice-leading theory to emphasize smaller movements because I\'m talking about a guitarist, not some general best practice.',
                'I took a music class in college on arranging for chorales that laid out the core rules for different voices (soprano, alto, tenor, and bass). The core message was that our ears and brains tend to like higher notes to move in smaller musical intervals, whereas lower notes sound better when they jump in large intervals. That works great for Bach chorales and extends well to other genres like jazz and pop. People generally enjoy hearing a melody line move in small intervals (with notable exceptions, such as Somewhere Over the Rainbow), and the bass line move in larger intervals, for example, fifths and octaves.',
                'So, why do I say that one of my main rules is to move voices in small intervals? The short answer is the bass player. VoicingVault helps to arrange examples for guitar players to practice, emphasizing jazz chords and theory. Most jazz bands have a bass player, who should play the leaping lower register. With the lower register handled, we guitarists can focus on following the upper structure rules with smaller intervals between notes.',
                'Another key simplification in VoicingVault\'s voice-leading rules is removing the melody from the chord arrangements. The best voice leading on guitar will involve outlining the melody line with one of the voices in each chord. VoicingVault does not currently allow for melody inputs, only chord inputs. I\'ve considered adding melody input and constraints to the platform, and I would love to hear what you think. If you have any opinion on factoring melody into the input, please use the \"Contact Us\" section lower on this page.'
            ]
        },
        {
            title: 'Why is good guitar comping so difficult to learn?',
            date: '7/13/2024',
            body: [
                'For the first ten years of my guitar playing, I was directed towards using one or two chord shapes for each chord type. For example, I knew two maj7 shapes. Typically, there would be a chord shape with the root on the E string and another with a root on the A string. The guitar is a transposition instrument, so learning a few chord shapes works well because you can simply move each of these shapes along the neck to have the correct root note.',
                'When I wanted to start learning more advanced jazz theory, I was introduced to drop 2 chord voicings. Drop 2 chord voicings are seventh chords that a guitarist can play by moving the second highest note of the chord down an octave. This method forms a chord that can be played easily on four adjacent strings, rather than having a string gap or an unnecessary repeated note. You can create a drop 2 chord with all four seventh chord inversions, making four chords per octave on each set of four adjacent strings (E-A-D-G, A-D-G-B, and D-G-B-e). Therefore, the guitar has 12 drop 2 chords every 12 frets.',
                'The change from choosing between a few basic chord shapes to 12 different drop 2 chords was huge. Say we have a simple chord progression like Autumn Leaves, which has 7 chords. I learned two shapes for each of those seventh chords when I was an intermediate guitarist. That means I had two choices for the Cmin7, two for the F7, and so on. Therefore, I had 2^7=128 possible ways of playing the chord progression. That’s a big number, but it was manageable when choosing a chord voicing based on the root position. With drop 2 chords, there are 12 choices for the Cmin7, 12 for the F7, and so on. That gives us a whopping 12^7=35,831,808 combinations of chord voicings to play on Autumn Leaves.',
                'How does anyone choose a few chords to comp through a progression, especially when learning good jazz theory? My answer is with voice-leading principles. Voice leading means treating each of the four notes in a seventh chord as a voice, the same way a chorale would work with soprano, alto, tenor, and bass. Each voice wants to move melodically, so we should follow a few rules for good voice leading. I’ll write a longer post about these rules at a different date, but the main principles I like to follow are maximizing common tones between chords (voices that stay the same) and moving voices in small intervals.',
                'VoicingVault generates good voice-leading practice examples to help you learn your jazz guitar chord voicings. Drop 2 chords are difficult to practice because there are so many voicing options. We generate practice materials using state-of-the-art AI practices, so you’ll have practice options for any chord progression.'
            ]
        }
    ]

    return (
        <div className="flex justify-center">
            <div className="hidden lg:block lg:w-2/12">
                {/* Table of Contents */}
                <div className="sticky top-20 bg-white shadow-md py-6 px-6 mr-4 rounded-lg">
                    <p className="text-2xl font-medium pb-4">Contents</p>
                    <ul>
                        {posts.map((post, index) => (
                            <li key={index} className="pb-4">
                                <a href={`#post-${index}`} className="text-lg hover:text-orange-500">
                                    {post.title}
                                </a>
                            </li>
                        ))}
                        <li key={posts.length + 1} className="pb-4">
                            <a href={`#contact`} className="text-lg hover:text-orange-500">
                                Contact Us
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="lg:w-6/12 bg-white shadow-md py-8 px-8 rounded-lg">
                {posts.map((post, index) => (
                    <div id={`post-${index}`} className="" key={index}>
                        <p className="text-3xl lg:pl-16 font-medium pb-2 pt-20 lg:pt-32">{post.title}</p>
                        <p className="text-xl lg:pl-16 pb-6">{post.date}</p>
                        {post.body.map((paragraph, paraIndex) => (
                            <p key={paraIndex} className="indent-8 text-xl lg:px-16 pb-6">{paragraph}</p>
                        ))}
                    </div>
                ))}
                <p id={`contact`} className="pt-24 text-3xl lg:pl-16 font-medium pb-4">We couldn't find enough chord voicing examples to practice</p>
                <p className="indent-8 text-xl lg:px-16 pb-6">
                    So, we created VoicingVault. VoicingVault uses music theory and AI to find good tablature suggestions for all chord progressions.
                    We also wanted to make it fun, so we built a play-along, a printable tablature format, and a chord progress tracker.
                </p>
                <p className="text-xl lg:px-16 pb-6">
                    We would love to hear what you think and if you would like to see any features added (or improved).
                </p>
                <p className="text-md lg:px-16 pb-4">
                    Please note: chord tablature generation technology is patent pending
                </p>
                <div className="flex flex-col lg:px-16 pt-12 py-12">
                    <p className="text-xl font-medium pb-2">Contact Us</p>
                    <TextInput placeholder={'name (optional)'} text={name} setText={setName} isPassword={false} />
                    <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="message"
                        className="py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
                    />
                    <div className="flex justify-center">
                        <Button small={true} onClick={contact} text={'Send'} />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default About