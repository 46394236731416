import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom'
import ChordPreview from '../components/ChordPreview';
import { serverAddress } from '../global'
import Button from '../components/Button'

const ChordProgress = () => {
    const navigate = useNavigate()

    const [chordsPlayed, setChordsPlayed] = useState([])

    const [anonymous, setAnonymous] = useState(false)

    const convertTimestamp = (timestamp) => {
        const date = new Date(timestamp)
        const options = { 
            month: '2-digit', 
            day: '2-digit', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit' 
        }
        return date.toLocaleString('en-US', options)
    }

    useEffect(() => {
        const loggedInUser = localStorage.getItem("email")

        if (loggedInUser === null) {
            setAnonymous(true)
        } else if (loggedInUser === 'anonymous') {
            // TODO deprecate
            setAnonymous(true)
        } else {
            fetch(`${serverAddress}get-chords-played/${loggedInUser}`)
            .then((response) => response.json())
            .then((data) => {
                setChordsPlayed(Object.keys(data).map(id => {
                    return {
                        timestamp: convertTimestamp(data[id].timestamp),
                        fingerings: data[id].fingerings
                    }
                }).reverse())
            })
        }
    }, [])

    return (
        <div className="pt-16 lg:pt-24">
            {(anonymous)? 
            <div className="shadow-md w-[80%] mx-auto my-4 flex flex-col items-center justify-center rounded-lg p-4">
                <p class="font-medium py-4 px-4 text-xl">Chord Progress</p>
                <p class="py-4 px-4 text-lg">Chords will only be saved when you have a registered account</p>
                <Button small={true} onClick={() => navigate('/signup')} text={'Create Account Here'}/>
            </div>
             :
            <div>
            <div className="shadow-md w-[80%] mx-auto my-4 flex flex-col items-center justify-center rounded-lg p-4">
                <p class="font-medium py-4 px-4 text-xl">Chord Progress</p>
                <p class="py-4 px-4 text-lg">Chord progressions will show up where when you have played them with the metronome</p>
            </div>
            <div className="px-8 lg:px-48">
                
                { chordsPlayed.map((chordData) => (
                    <div>
                        <p class="font-medium py-4 px-4 lg:text-lg">Played on {chordData.timestamp}</p>
                        {chordData.fingerings.map((fingering) => (
                            <ChordPreview notes={fingering['chord_fingering']} chordLetter={fingering['chord_letter']} chordType={fingering['chord_type']}/>
                        ))}
                    </div>
                ))}
            </div>
            </div>
            }
        </div>
    )
}

export default ChordProgress