import React, { useState, useEffect, useRef } from 'react';

const TextInputWithDropdown = ({ options, value, setText, setValue, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Close the dropdown when clicking outside of it
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) =>
        option.toLowerCase().startsWith(value.toLowerCase())
      )
    );
  }, [value, options]);

  const handleInputChange = (event) => {
    setText(event.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setValue(option);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <input
        type="text"
        value={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        onFocus={() => setIsOpen(true)}
        className="box-border w-full py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
      />
      {isOpen && filteredOptions.length > 0 && (
        <ul className="absolute z-10 mt-1 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              className="cursor-pointer select-none relative py-2 pl-3 pr-9 hover:bg-gray-100"
              onClick={() => handleOptionClick(option)}
            >
              <span className="block truncate">{option}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextInputWithDropdown;