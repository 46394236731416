import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from './Button';
import Modal from './Modal';
import Logo from './Logo';

const MobileBanner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [confirmLogout, setConfirmLogout] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false);

  const at = (path) => {
    return location.pathname == path
  }

  const logout = () => {
    localStorage.removeItem('email')
    window.location.reload()
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-white p-3 flex flex-row items-center h-16">
      <NavLink className="flex flex-row items-center" to="/">
        <div className='w-6 h-12 pt-2 drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]'>
          <Logo/>
        </div>
        <h1 className="text-2xl font-medium drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">VoicingVault</h1>
      </NavLink>
      
      <div className="ml-auto flex items-center">
        <button
          className="text-3xl"
          onClick={() => setMenuOpen(true)}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12H21M3 6H21M3 18H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </button>
      </div>

      {menuOpen && (
        <div className="fixed inset-0 bg-white z-50 flex flex-col p-4">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-medium"></h2>
            <button
              className="text-3xl"
              onClick={() => setMenuOpen(false)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 18L18 6M6 6L18 18" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
          <div className="mt-8 flex flex-col space-y-4">
            <NavLink className={"text-2xl hover:text-orange-500" + (at("/player") ? ' text-orange-500' : '')} to="/player" onClick={() => setMenuOpen(false)}>
              Player
            </NavLink>
            <NavLink className={"text-2xl hover:text-orange-500" + (at("/progress") ? ' text-orange-500' : '')} to="/progress" onClick={() => setMenuOpen(false)}>
              Progress
            </NavLink>
            <NavLink className={"text-2xl hover:text-orange-500" + (at("/blog") ? ' text-orange-500' : '')} to="/blog" onClick={() => setMenuOpen(false)}>
              Blog
            </NavLink>
            {!localStorage.getItem('email') ? (
              <Button text={'Create Account'} onClick={() => { navigate('/signup'); setMenuOpen(false); }} />
            ) : (
              <p onClick={() => { setConfirmLogout(true); setMenuOpen(false); }} className="cursor-pointer text-2xl hover:text-orange-500">Sign out</p>
            )}
          </div>
        </div>
      )}
      <Modal isOpen={confirmLogout} onClose={() => setConfirmLogout(false)} largeContent={false}>
        <div className="flex flex-col items-center">
          <p className="text-xl font-medium p-8">Are you sure you want to sign out?</p>
          <div className="flex flex-row">
            <div className="bg-red-500 rounded-lg hover:opacity-70 cursor-pointer flex justify-center items-center" onClick={logout}>
              <p className='py-2 px-4 font-medium text-3xl text-white'>Sign out</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MobileBanner;