import React, { useState } from 'react';
import TextInput from '../components/TextInput';
import {useNavigate} from 'react-router-dom'
import Button from '../components/Button';
import { createUser, createVerificationCode } from '../api/api';
import { useCheckLoggedIn } from '../auth/loginCheck';
import Logo from '../components/Logo'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const SignUp = () => {
    useCheckLoggedIn()

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return regex.test(email)
    }

    const createAndVerify = async () => {
        const toastID = toast.loading('Creating Account...', {
            position: 'top-center'
        })
        if (password !== confirmPassword) {
            toast.dismiss(toastID)
            toast.error('Passwords do not match', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        if (password.length < 8) {
            toast.dismiss(toastID)
            toast.error('Password must be at least 8 characters long', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        if (!validateEmail(email)) {
            toast.dismiss(toastID)
            toast.error('Invalid email format', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        const status = await createUser({email: email.toLowerCase(), password: password})
        if (status == true) {
            const codeStatus = await createVerificationCode({email: email.toLowerCase()})
            // Dismiss toast
            toast.dismiss(toastID)
            if (codeStatus == true) {
                navigate('/verify', {state: {email: email.toLowerCase()}})
            } else {
                toast.error(codeStatus.error, {
                    type: 'error',
                    position: 'top-center',
                    autoClose: 4000
                })
            }
        } else {
            toast.dismiss(toastID)
            toast.error(status.error, {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }

        
    }

    return (
        <div className="flex justify-center items-center py-16">
            <div className="shadow-lg px-12 py-8 flex flex-col">
                <div className="flex flex-row justify-center pb-4">
                    <div className="w-6 h-12">
                        <Logo />
                    </div>
                    <p className="pt-2 font-medium text-2xl">VoicingVault</p>
                </div>
                <div className="flex flex-col items-center">
                    <TextInput placeholder={'email'} text={email} setText={setEmail} isPassword={false}/>
                    <TextInput placeholder={'password'} text={password} setText={setPassword} isPassword={true}/>
                    <TextInput placeholder={'confirm password'} text={confirmPassword} setText={setConfirmPassword} isPassword={true}/>
                    <div className="pt-4">
                        <Button small={true} onClick={createAndVerify} text={'Sign Up'} />
                    </div>
                    <div className="pt-4 flex flex-row">
                        <p className="pt-2 pr-2 font-medium text-md">Have An Account?</p>
                        <p onClick={() => navigate('/login')} className="pt-2 font-medium text-md hover:text-orange-500 cursor-pointer">Log In</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default SignUp