const Logo = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="100%" 
            preserveAspectRatio="xMinYMin slice" 
            width="100%" 
            viewBox="0 0 150 150"
        >
            <path d="M28.3519 119.235V67.1059H35.1404V119.235H28.3519Z" fill="#A45729"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6731 66.5234H35.8193V119.818H27.6731V66.5234ZM29.0308 67.6883V118.653H34.4616V67.6883H29.0308Z" fill="black"/>
            <path d="M1 2L31.9036 35.0514L58 2V8.14229L31.9036 76L1 8.14229V2Z" fill="#FFA500" stroke="#FFA500"/>
            <path d="M25 123.282L27.9474 120H36.0526L39 123.282L34.579 136H29.421L25 123.282Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2346 119H35.7654L39 122.923L34.5077 137H28.4923L24 122.923L27.2346 119ZM27.9343 120.161L25.4736 123.146L29.5242 135.839H33.4758L37.5264 123.146L35.0657 120.161H27.9343Z" fill="black"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M31.7738 32.9188L2.34929 0L0.186569 1.40753L31.9617 36.9561L58.8509 1.33213L56.5642 0.0753973L31.7738 32.9188ZM0 8.66649L2.53586 7.82708L31.7597 72.1074L56.4152 7.88552L59 8.60804L31.9758 79L0 8.66649Z" fill="black"/>
        </svg>
    )
}

export default Logo