import React from 'react'

const ChordPreview = (props) => {
    const notes = [...props.notes].reverse()
    // Find the minimum non-negative note to determine the starting fret
    const startingFret = Math.min(...notes.filter(note => note !== -1), Infinity)

    // Calculate the number of frets needed to accommodate all the notes
    const numFrets = 5

    const fretWidth = 3;
    const stringSpacing = 30;
    const fretSpacing = 40;

    const pad = 20

    return (
        <div className="shadow-md my-4 mx-4 rounded-lg p-4 inline-block">
            <svg width={numFrets * fretSpacing + fretWidth} height={6 * stringSpacing + pad}>
                <text x="13" y="15" font-weight="bold">{startingFret}</text>
                <text x="100" y="15" font-weight="bold">{props.chordLetter + props.chordType}</text>
                {/* Draw frets */}
                {Array.from({ length: numFrets + 1 }, (_, fretIndex) => (
                    <rect
                    key={fretIndex}
                    x={fretIndex * fretSpacing}
                    y={pad}
                    width={fretWidth}
                    height={6 * stringSpacing}
                    fill="#222222"
                    />
                ))}

                {/* Draw strings */}
                {Array.from({ length: 6 }, (_, stringIndex) => (
                    <line
                    key={stringIndex}
                    x1={0}
                    y1={(stringIndex + 1/2) * stringSpacing + pad}
                    x2={numFrets * fretSpacing}
                    y2={(stringIndex + 1/2) * stringSpacing + pad}
                    stroke="#999999"
                    strokeWidth={2}
                    />
                ))}

                {/* Draw notes */}
                {notes.map((note, stringIndex) => (
                    note !== -1 && (
                    <circle
                        key={stringIndex}
                        cx={(note - startingFret + 1/2) * fretSpacing}
                        cy={(stringIndex + 1/2) * stringSpacing + pad}
                        r={8}
                        fill="#DD6B20"
                    />
                    )
                ))}
            </svg>
        </div>
    );
}

export default ChordPreview