const TextInput = ({text, setText, placeholder, isPassword}) => {
    return (
        <input
            type={(isPassword) ? "password" : "text"}
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={placeholder}
            className="box-border py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
        />
    )
}

export default TextInput