import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from './Button'
import { serverAddress } from '../global';
import TablatureDisplay from './TablatureDisplay'
import GuitarNeck from './GuitarNeck'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const PlayerInteractive = ({ chordFingerings, beatsPerMeasure, chordNames, chordData }) => {
    
    const navigate = useNavigate()

    const [interacting, setInteracting] = useState(false)

    const audio = new Audio('/tick.wav')
    const [isPlaying, setIsPlaying] = useState(false)
    const [intervalID, setIntervalID] = useState()

    const [ticks, setTicks] = useState(-1)

    let tickTrackerOverall = 0
    let saved = false

    const playClick = () => {
        tickTrackerOverall += 1
        if (tickTrackerOverall > chordFingerings.length && !saved) {
            saveChordsPlayedToDB()
        }

        setTicks(ticks => {
            // The total number of ticks in the rotation is the length of the chordFingerings array
            return (ticks + 1) % chordFingerings.length
        })
        audio.play()
    }

    const startMetronome = () => {
        setIsPlaying(true)
        setTicks(-1)
        playClick()

        // Calculate the interval in milliseconds based on BPM
        const interval = 60000 / 40

        // Set up a recurring interval to play the click
        let intervalId = setInterval(() => {
            playClick()
        }, interval)
        setIntervalID(intervalId)
    }

    const stopMetronome = () => {
        setIsPlaying(false)

        // Clear the interval when stopping the metronome
        clearInterval(intervalID)
    }

    const toggleMetronome = () => {
        if (isPlaying) {
            stopMetronome()
        } else {
            startMetronome()
        }
    }

    const saveChordsPlayedToDB = async () => {
        if (saved) {
            return
        }
        if (!localStorage.getItem("email") || localStorage.getItem("email") === 'anonymous') {
            return
        }

        const toastID = toast.loading('Saving...', {
            position: 'top-center'
        })

        saved = true

        const chord_shape_ids = []
        const chord_roots = []

        for (const data of chordData) {
            chord_shape_ids.push(data.chord_shape_id)
            chord_roots.push(data.root_note_pos)
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                player_id: localStorage.getItem("email"),
                chord_ids: chord_shape_ids,
                chord_root_positions: chord_roots,
                times_looped: 1
            })
        }

        await fetch(`${serverAddress}track-chord-played`, requestOptions)

        toast.dismiss(toastID)
        toast.success('Saved!', {
            type: 'success',
            position: 'top-center',
            autoClose: 2000
        })
    }

    const openInteract = () => {
        setTicks(0)
        setInteracting(true)
    }

    const closeInteract = () => {
        setInteracting(false)
        setTicks(-1)
    }

    const toExport = () => {

        const stateToPrint = { 
            chordFingerings: chordFingerings, 
            beatsPerMeasure: beatsPerMeasure, 
            chordNames: chordNames
        }
        localStorage.setItem('printState', JSON.stringify(stateToPrint));
        
        const newTabUrl = `${window.location.origin}/export`;
        window.open(newTabUrl, '_blank');
    }
    
    return (
        <div>
          <div className="bg-white w-[80%] mx-auto my-4 items-center justify-center rounded-lg p-4" style={(interacting) ? {paddingBottom: 240} : {paddingBottom: 140}}>
            <TablatureDisplay 
              notes={chordFingerings} 
              bpmeas={beatsPerMeasure} 
              chords={chordNames}
              tick={ticks}
            />
          </div>
          <div className="fixed bottom-0 w-full bg-white text-white p-4 flex justify-center">
            {(interacting) ? 
            <div className="flex w-full justify-between">
                <div className="pl-24 w-full">
                    <GuitarNeck chordFingerings={chordFingerings} metronomeTicks={ticks}/>
                </div>
                <div className="flex flex-col justify-between w-full">
                    <div className="flex justify-end">
                        <Button text={'-'} onClick={closeInteract}/>
                    </div>
                    <div className="flex justify-center">
                        <div className="w-3/12">
                            <Button text={(isPlaying) ? '■' : '▶'} onClick={toggleMetronome}/>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className="w-5/12 flex justify-between">
              <div className="w-1/2 px-2 lg:px-10">
                <Button text={'Play'} onClick={openInteract}/>
              </div>
              <div className="w-1/2 px-2 lg:px-10">
                <Button text={'Print'} onClick={toExport}/>
              </div>
            </div>
            }
            
          </div>
          <ToastContainer />
        </div>
    )
}

export default PlayerInteractive