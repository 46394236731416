import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useCheckLoggedIn = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('email')) {
            navigate('/player');
        }
    }, [navigate]);
};

const useCheckLoggedOut = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('email')) {
            navigate('/login');
        }
    }, [navigate]);
};

export { useCheckLoggedIn, useCheckLoggedOut };