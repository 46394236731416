import React, { useState } from 'react';
import TextInput from '../components/TextInput';
import {useNavigate} from 'react-router-dom'
import Button from '../components/Button';
import { login } from '../api/api';
import { useCheckLoggedIn } from '../auth/loginCheck';
import Logo from '../components/Logo'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const Login = () => {
    useCheckLoggedIn()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const toSignup = () => {
        navigate(`/signup`)
    }
    
    const toRequestReset = () => {
        navigate('/request-password-reset')
    }

    const logIn = async () => {
        const toastID = toast.loading('Logging In...', {
            position: 'top-center'
        })

        const res = await login({email: email.toLowerCase(), password: password})
        
        toast.dismiss(toastID)

        if (res.email === email) {
            localStorage.setItem('email', email)

            navigate('/player')
        } else {
            toast.error(res.error, {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }
    }

    return (
        <div className="flex justify-center items-center py-16">
            <div className="shadow-lg px-12 py-8 flex flex-col">
                <div className="flex flex-row justify-center pb-4">
                    <div className="w-6 h-12">
                        <Logo />
                    </div>
                    <p className="pt-2 font-medium text-2xl">VoicingVault</p>
                </div>
                <div className="flex flex-col items-center">
                    <TextInput placeholder={'email'} text={email} setText={setEmail} isPassword={false}/>
                    <TextInput placeholder={'password'} text={password} setText={setPassword} isPassword={true}/>
                    <div className="pt-4">
                        <Button small={true} onClick={logIn} text={'Log In'} />
                    </div>
                    <div className="pt-4">
                        <p onClick={toRequestReset} className="pt-2 font-medium text-md hover:text-orange-500 cursor-pointer">Reset Password</p>
                    </div>
                    <div className="pt-4 flex flex-row">
                        <p className="pt-2 pr-2 font-medium text-md">No Account?</p>
                        <p onClick={toSignup} className="pt-2 font-medium text-md hover:text-orange-500 cursor-pointer">Sign Up</p>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
    )
}

export default Login