import React, { useState } from 'react';
import TextInput from '../components/TextInput';
import {useNavigate, useLocation} from 'react-router-dom'
import Button from '../components/Button';
import { createVerificationCode, verifyUser } from '../api/api';
import { useCheckLoggedIn } from '../auth/loginCheck';
import Logo from '../components/Logo'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const EmailVerify = () => {
    useCheckLoggedIn()

    const navigate = useNavigate()
    const { state } = useLocation()

    const [code, setCode] = useState('')

    const verify = async () => {
        // Email will be lower case because it is sent from the signup page
        const status = await verifyUser({email: state.email, code: code})
        if (status == true) {
            // Keep the email in local storage to keep the user logged in
            localStorage.setItem('email', state.email)
            navigate('/player')
        } else {
            toast.error('Error verifying user', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }
    }

    const resendEmail = async () => {
        const toastID = toast.loading('Creating New Code...', {
            position: 'top-center'
        })
        const codeStatus = await createVerificationCode({email: state.email})
        toast.dismiss(toastID)
        if (codeStatus == false) {
            toast.error('Failed to send email. Please try again.', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }
    }

    return (
        <div className="flex justify-center items-center py-16">
            <div className="shadow-lg px-12 py-8 flex flex-col">
                <div className="flex flex-row justify-center pb-4">
                    <div className="w-6 h-12">
                        <Logo />
                    </div>
                    <p className="pt-2 font-medium text-2xl">VoicingVault</p>
                </div>
                <div className="flex flex-col items-center">
                    <p>We just sent a <b>6-digit</b> code to your email. Please input the code in the box below to verify your account.</p>
                    <TextInput placeholder={'code'} text={code} setText={setCode} isPassword={false}/>
                    <div className="pt-4">
                        <Button small={true} onClick={verify} text={'Verify'}/>
                    </div>
                    <div className="pt-4 flex flex-row">
                        <p className="pt-2 pr-2 font-medium text-md">No Code?</p>
                        <p onClick={resendEmail} className="pt-2 font-medium text-md hover:text-blue-500">Email Again</p>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default EmailVerify