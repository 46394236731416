import TablatureDisplay from '../components/TablatureDisplay'
import { useEffect, useState } from 'react'

const Export = () => {
    const [state, setState] = useState({
        chordFingerings: [],
        beatsPerMeasure: 4,
        chordNames: []
    });
    const [isStateSet, setIsStateSet] = useState(false);

    useEffect(() => {
        const storedState = localStorage.getItem('printState');
        if (storedState) {
            setState(JSON.parse(storedState));
            localStorage.removeItem('printState');
        }
    }, []);

    useEffect(() => {
        if (isStateSet) {
            window.print();
        }
    }, [isStateSet]);

    useEffect(() => {
        if (state.chordFingerings.length > 0 || state.chordNames.length > 0) {
            setIsStateSet(true);
        }
    }, [state]);

    return (
        <div>
            <TablatureDisplay 
                notes={state.chordFingerings} 
                bpmeas={state.beatsPerMeasure} 
                chords={state.chordNames}
            />
        </div>
    )
}

export default Export;
