
import React, { useState } from 'react';
import ChordInput from '../components/ChordInput';
import PlayerInteractive from '../components/PlayerInteractive';
import { ToastContainer } from 'react-toastify';


const Player = () => {

  

  const [beatsPerMeasure, setBeatsPerMeasure] = useState(4)
  const [chordFingerings, setChordFingerings] = useState([])
  const [chordData, setChordData] = useState([])
  const [chordNames, setChordNames] = useState([])
  
  return (
    <div className="h-screen pt-16 lg:pt-24">
      <div>
        <div className="shadow-md lg:w-[80%] mx-auto my-4 flex items-center justify-center rounded-lg p-4">
          <ChordInput 
            setChordFingerings={setChordFingerings} 
            setChordData={setChordData}
            setChordNames={setChordNames}
            bpmeas={beatsPerMeasure}
            setBeatsPerMeasure={setBeatsPerMeasure}
          />
        </div>
        {(chordFingerings.length > 0) ?
        <PlayerInteractive
          chordFingerings={chordFingerings}
          beatsPerMeasure={beatsPerMeasure}
          chordNames={chordNames}
          chordData={chordData}
        />
        :<span/>
        }
      </div>
      <ToastContainer />
    </div>
  );
};

export default Player;