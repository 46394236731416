import React from 'react';

const BarDisplay = ({ parentIndex, bpmeas, notes, chords, tick }) => {
  const stringNames = ['e', 'B', 'G', 'D', 'A', 'E'];
  const width = 200;
  const height = 60;
  const stringSpacing = height / (stringNames.length - 1);
  const noteSpacing = width / bpmeas;

  return (
    <svg
      className="w-full"
      viewBox={`0 0 ${width} ${height + 25}`}
      preserveAspectRatio="xMidYMid meet"
    >
      <g>
        {chords.map((chord, index) => (
          <text
            key={index}
            x={index * noteSpacing + noteSpacing / 2}
            y="10"
            fontSize="10"
            textAnchor="middle"
            fontWeight="bold"
          >
            {chord.length > 0 ? chord : ''}
          </text>
        ))}
        {stringNames.map((_, stringIndex) => (
          <line
            key={stringIndex}
            x1="0"
            y1={stringIndex * stringSpacing + 20}
            x2={width}
            y2={stringIndex * stringSpacing + 20}
            stroke="black"
            strokeWidth="1"
          />
        ))}
        {notes.map((note, noteIndex) => (
          note.map((n, stringIndex) => (
            n && (
                <g>
                    <rect 
                        x={noteIndex * noteSpacing + noteSpacing / 2 - 5}
                        y={stringIndex * stringSpacing + 15}
                        width="10"
                        height="10"
                        fill="white"
                    />
                    <text
                        key={stringIndex}
                        x={noteIndex * noteSpacing + noteSpacing / 2}
                        y={stringIndex * stringSpacing + 25}
                        fontSize="10"
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        dy="-0.35em"
                        fill={((parentIndex * bpmeas) + noteIndex == tick) ? "#F97316": "black"}
                    >
                        {n}
                    </text>
                </g>
            )
          ))
        ))}
        {/* Measure lines */}
        {Array(Math.ceil(notes.length / bpmeas) + 1).fill('').map((_, measureIndex) => (
          <line
            key={measureIndex}
            x1={measureIndex * bpmeas * noteSpacing}
            y1="20"
            x2={measureIndex * bpmeas * noteSpacing}
            y2={height + 20}
            stroke="black"
            strokeWidth="1"
          />
        ))}
      </g>
    </svg>
  );
};

export default BarDisplay;