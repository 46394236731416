import React from 'react';

const GuitarNeck = (props) => {

  const stringCount = 6 // Number of strings
  const fretCount = 20 // Number of frets

  const fretWidth = 50
  const stringSpacing = 30
  const fretSpacing = 50

  const neckWidth = fretWidth * fretCount
  const neckHeight = stringSpacing * (stringCount)

  const padding = stringSpacing / 2

  return (
    <div className="flex items-center justify-center">
      <svg 
        className="w-full"
        viewBox={`0 0 ${neckWidth} ${neckHeight}`}
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Draw frets */}
        {[...Array(fretCount)].map((_, index) => (
          <rect
            key={index}
            x={index * fretSpacing}
            y={0}
            width={fretWidth}
            height={neckHeight}
            fill="#FFFFFF"
            stroke="#333333"
            strokeWidth={4}
          />
        ))}

        {/* Draw strings */}
        {[...Array(stringCount)].map((_, index) => (
          <line
            key={index}
            x1={0}
            y1={index * stringSpacing + padding}
            x2={neckWidth}
            y2={index * stringSpacing + padding}
            stroke="#999999"
            strokeWidth={2}
          />
        ))}

        {/* Draw fret markers */}
        {[3, 5, 7, 9, 12, 15, 17, 19].map((fretNumber) => (
          (fretNumber == 12) ? 
          <g key={fretNumber}>
            <circle
              cx={(fretNumber - 1) * fretSpacing + fretWidth / 2}
              cy={neckHeight / 3}
              r={8}
              fill="#fff"
              stroke="#333"
              strokeWidth={2}
            />
            <circle
              cx={(fretNumber - 1) * fretSpacing + fretWidth / 2}
              cy={2 * neckHeight / 3}
              r={8}
              fill="#fff"
              stroke="#333"
              strokeWidth={2}
            />
          </g>
          :
          <circle
            key={fretNumber}
            cx={(fretNumber - 1) * fretSpacing + fretWidth / 2}
            cy={neckHeight / 2}
            r={8}
            fill="#fff"
            stroke="#333"
            strokeWidth={2}
          />
        ))}
        {/* Draw current chord fingering */}
        {props.chordFingerings[Math.floor(props.metronomeTicks) % props.chordFingerings.length].map((fret, string_idx) => (
          <circle
            key={string_idx}
            cx={(fret - 1) * fretSpacing + fretWidth / 2}
            cy={stringSpacing * (string_idx) + padding}
            r={9}
            fill="#DD6B20"
            strokeWidth={2}
          />
        ))}
      </svg>
    </div>
  )
}

export default GuitarNeck