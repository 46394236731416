"use client";

import React, { useState, useEffect, useRef } from 'react';
import { serverAddress } from '../global';
import 'react-dropdown/style.css';
import Button from './Button';
import BarInput from './BarInput';
import { FaCog, FaSearch, FaQuestion } from 'react-icons/fa'
import Modal from './Modal';
import TextInputWithDropdown from './TextInputWithDropdown';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const ChordInput = ({setChordFingerings, setChordData, setChordNames, bpmeas, setBeatsPerMeasure}) => {
  const [textInputs, setTextInputs] = useState(Array(bpmeas).fill(''))

  const [searchOpen, setSearchOpen] = useState(false)
  const [helpOpen, setHelpOpen] = useState(false)
  const [chordTemplates, setChordTemplates] = useState([])
  const [settingsOpen, setSettingsOpen] = useState(false)

  const [templateSearch, setTemplateSearch] = useState('')

  // const chordTypeOptions = ['chords', 'arpeggios']

  const noteNames = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#']
  const chordTypes = ['maj', 'min', 'maj7', 'min7', '7', 'min7b5', 'dim7']

  const allChordNames = noteNames.flatMap(note => {
    return chordTypes.map(chordType => `${note}${chordType}`)
  })

  const addBar = () => {
    const values = [...textInputs]
    for (let i = 0; i < bpmeas; i++) {
      values.push('')
    }
    setTextInputs(values)
  }

  const processRawFingerings = (fingerings) => {
    // Make everything into correct string tab format (turn -1 into '')
    // Also reverse the order of the fingerings
    // Also, copy the tab of the previous chord for every beat with a blank chord
    const result = []
    let curFingering = []
    let curFingeringIndex = 0
    for (let i = 0; i < textInputs.length; i++) {
      if (textInputs[i].length > 0) {
        curFingering = []
        // For some reason I made the API return the fingering in reverse order
        for (let j = 5; j >= 0; j--) {
          if (fingerings[curFingeringIndex][j] === -1) {
            curFingering.push('')
          } else {
            curFingering.push(fingerings[curFingeringIndex][j])
          }
        }
        curFingeringIndex++
      }

      result.push(curFingering)
    }

    setChordFingerings(result)
  }

  const search = () => {
    setSearchOpen(true)
    getChordTemplates()
  }

  const getChordTemplates = () => {
    setChordTemplates({
      'Autumn Leaves' : {bpmeas: 4, chords: ['Cmin7', '', 'F7', '', 'A#maj7', '', 'D#maj7', '', 'Amin7b5', '', 'D7', '', 'Gmin7', '', '', '']},
      'On The Sunny Side of the Street': {bpmeas: 4, chords: ['Cmaj7', '', '', '', 'E7', '', '', '', 'Fmaj7', '', '', '', 'Bmin7b5', '', 'E7', '', 'Amin7', '', '', '', 'D7', '', '', '', 'Dmin7', '', 'G7', '', 'Emin7', 'A7', 'Dmin7', 'G7']},
      'Giant Steps' : {bpmeas: 4, chords: ['Bmaj7', '', 'D7', '', 'Gmaj7', '', 'A#7', '', 'D#maj7', '', '', '', 'Amin7', '', 'D7', '', 'Gmaj7', '', 'A#7', '', 'D#7', '', 'F#7', '', 'Bmaj7', '', '', '', 'Fmin7', '', 'A#7', '', 'D#maj7', '', '', '', 'Amin7', '', 'D7', '', 'Gmaj7', '', '', '', 'C#min7', '', 'F#7', '', 'Bmaj7', '', '', '', 'Fmin7', '', 'A#7', '', 'D#maj7', '', '', '', 'C#min7', '', 'F#7', '']}
    })
  }

  const selectChordTemplate = (option) => {
    setBeatsPerMeasure(chordTemplates[option].bpmeas)
    setTextInputs(chordTemplates[option].chords)
    setSearchOpen(false)
  }

  const SearchModal = () => {
    return (
      <div className="px-10">
        <p className="font-medium text-2xl pb-6">Chord Templates</p>
        <div className="w-full flex justify-center">
          <TextInputWithDropdown options={Object.keys(chordTemplates)} value={templateSearch} setText={setTemplateSearch} setValue={selectChordTemplate} placeholder="Search" />
        </div>
      </div>
    )
  }

  const help = () => {
    setHelpOpen(true)
  }

  const HelpModal = () => {
    return (
      <div className="flex flex-col items-center">
        <div style={{ maxWidth: '40rem' }}>
          <p className="font-medium text-2xl pb-6">Help</p>
          <p className="p-4">The player allows you to input chord progressions and generates smart tablature to effectively voice each chord in sequence. This process is known as voice leading, and needs careful practice to perfect.</p>
          <p className="p-4">To input any chord progression, click on the text inputs on the chord chart. A dropdown will open with all valid chord names. You can also type the chord name with your keyboard. All note names must be capital letters. The valid chord types are maj, min, maj7, min7, 7, min7b5, and dim7. The system <b>does not currently</b> have flat notes, it only has sharp notes.</p>
          <p className="p-4">To add a measure, click the + icon.</p>
          <p className="p-4">Click on the search icon to search for chord templates. Please let us know if we made any mistakes in the chord templates, or if you have a chord progression you would like to see added as a template.</p>
          <p className="p-4">Click on the gear icon to access the settings. Each / marks one beat in the music, which you can update by changing the beats per measure.</p>
        </div>
      </div>
    )
  }

  const settings = () => {
    setSettingsOpen(true)
  }

  const updateBeatsPerMeasure = (e) => {
    const newBpmeas = +e.target.value
    if (newBpmeas < 1 || newBpmeas > 11) {
      return
    }
    setBeatsPerMeasure(newBpmeas)
    setTextInputs(Array(newBpmeas).fill(''))
  }

  const [selectedStrings, setSelectedStrings] = useState([true, true, true, true, true, true])
  const [minimumFret, setMinimumFret] = useState(0)
  const [maximumFret, setMaximumFret] = useState(22)

  const handleStringChange = (index) => {
    const newSelectedStrings = [...selectedStrings]
    newSelectedStrings[index] = !newSelectedStrings[index]
    setSelectedStrings(newSelectedStrings)
  }

  const SettingsModal = () => {
    const notes = ['E', 'A', 'D', 'G', 'B', 'e']
    return (
      <div className="px-10">
        <p className="font-medium text-2xl pb-8">Settings</p>
        <div className="flex">
          <p className="">Beats per measure</p>
          <input 
            type="number" 
            value={bpmeas} 
            onChange={updateBeatsPerMeasure}
            min={1}
            max={11}
            className="box-border w-full py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
          />
        </div>
        <div className="flex flex-col pt-8">
          <p className="pb-2">Allowed Strings</p>
          <div className="mb-4 grid grid-cols-6 gap-2">
            {selectedStrings.map((isSelected, index) => (
              <label key={index} className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={isSelected}
                  onChange={() => handleStringChange(index)}
                  className="form-checkbox h-5 w-5"
                />
                <span>{notes[index]}</span>
              </label>
            ))}
          </div>
          <div className="flex pt-8">
            <p className="pr-2">Minimum Fret</p>
            <input 
              type="number" 
              value={minimumFret} 
              onChange={(e) => setMinimumFret(+e.target.value)}
              min={0}
              max={maximumFret - 1}
              className="box-border w-full py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          <div className="flex pt-8">
            <p className="pr-2">Maximum Fret</p>
            <input 
              type="number" 
              value={maximumFret} 
              onChange={(e) => setMaximumFret(+e.target.value)}
              min={minimumFret + 1}
              max={22}
              className="box-border w-full py-1 my-1 px-1 rounded-md border border-gray-300 outline-none focus:ring-1 focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
        </div>
      </div>
    )
  }

  const [generating, setGenerating] = useState(false)

  const generate = () => {
    setGenerating(true)

    fetch(`${serverAddress}chord-query`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            chord_list: textInputs,
            allowed_strings: selectedStrings,
            min_fret: minimumFret,
            max_fret: maximumFret
        }),
    }).then((response) => response.json())
      .then((data) => {
        if ('error' in data && data.error === 'Bad Search Constraints') {
          toast.error('Your search constraints are too strict, please widen your search', {
            type: 'error',
            position: 'top-center',
            autoClose: 4000
          })
          setGenerating(false)
          return
        }
        if (data.length === 0) {
          toast.error('Please include at least one chord name in your search', {
            type: 'error',
            position: 'top-center',
            autoClose: 4000
          })
          setGenerating(false)
          return
        }
        setChordNames(textInputs)
        processRawFingerings(data.map((chord) => chord.fingering))
        setChordData(data)
        setGenerating(false)
      }).catch((error) => {
        setGenerating(false)
        toast.error('An error occured. Please check the ? icon for syntax hints.', {
          type: 'error',
          position: 'top-center',
          autoClose: 4000
        })
      })
  }

  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const handleOrientationChange = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', handleOrientationChange);
    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  return (
    <div className="w-[100%]">
      {(isLandscape) ?
      <div className={`w-[100%] ${generating ? 'pointer-events-none opacity-30' : ''}`}>
        
        <div className="flex w-[100%]">

          <div className="">
            <FaSearch onClick={search} className="m-4 hover:opacity-50 cursor-pointer" size={30}/>
          </div>

          <div className="m-4 lg:max-w-[60%] mx-auto flex flex-wrap border-black">
            {Array(Math.ceil(textInputs.length / bpmeas)).fill('').map((_, index) => (
              <BarInput
                bpmeas={bpmeas} 
                options={allChordNames} 
                input={textInputs} 
                setInput={setTextInputs} 
                startingIndex={index * bpmeas} 
                addBar={addBar} 
                isLast={index === Math.ceil(textInputs.length / bpmeas) - 1}
              />
            ))}
          </div>

          <div className="flex flex-col justify-between">
            <div className="flex justify-between">
              <FaQuestion onClick={help} className="m-4 hover:opacity-50 cursor-pointer" size={30}/>
              <FaCog onClick={settings} className="m-4 hover:opacity-50 cursor-pointer" size={30}/>
            </div>
            <div className="">
              <Button text={'Generate'} onClick={generate}/>
            </div>
          </div>
        </div>
        <Modal isOpen={searchOpen} onClose={() => setSearchOpen(false)} largeContent={false}>
          {SearchModal()}
        </Modal>
        <Modal isOpen={helpOpen} onClose={() => setHelpOpen(false)} largeContent={true}>
          {HelpModal()}
        </Modal>
        <Modal isOpen={settingsOpen} onClose={() => setSettingsOpen(false)} largeContent={true}>
          {SettingsModal()}
        </Modal>
      </div>
    : 
    <div className="flex justify-center">
      <p className="font-medium">Please rotate your device to landscape mode</p>
    </div>}
    </div>
  );
};

export default ChordInput;