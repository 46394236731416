import TextInputWithDropdown from "./TextInputWithDropdown"
import Button from "./Button"

const BarInput = ({bpmeas, options, input, setInput, startingIndex, addBar, isLast}) => {

    const handleInputChange = (val, index) => {
        const values = [...input]
        values[index] = val
        setInput(values)
    }

    return (
        <div className="flex items-center ml-[-2px]">
        <div className="relative">
            <table className="min-w-full bg-white">
            <thead>
                <tr className="w-full">
                {Array(bpmeas).fill('').map((_, index) => (
                    <th key={index} className="px-1 py-2 text-left">
                    <div className="w-20">
                        <TextInputWithDropdown 
                        options={options} 
                        value={input[index + startingIndex]} 
                        setText={(val) => handleInputChange(val, index + startingIndex)} 
                        setValue={(val) => handleInputChange(val, index + startingIndex)} 
                        placeholder={(index + startingIndex === 0) ? 'Chord' : ''} 
                        />
                    </div>
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
                <tr className="w-full border-x-2 border-gray-800">
                {Array(bpmeas).fill('').map((_, index) => (
                    <td key={index} className="px-2 py-2 text-center font-bold">/</td>
                ))}
                </tr>
            </tbody>
            </table>
            {/*
            <button 
                className="absolute rounded-lg inset-0 w-full h-1/2 transform translate-y-[100%] opacity-0 hover:opacity-80 transition-opacity duration-200 flex items-center justify-center bg-red-500 text-white"
                onClick={() => alert('hi')}
            >
                Delete
            </button>
            */}
        </div>
        {isLast && (
            <div className="pl-8">
            <Button text={'+'} onClick={addBar} />
            </div>
        )}
        </div>
    )
}

export default BarInput