export const baseUrl = "https://voicingvault.onrender.com"
// const baseUrl = 'http://localhost:8000'

const login = async (data) => {
    const res = await fetch(`${baseUrl}/login-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const email = await res.json()
    return email
}

const createUser = async (data) => {
    const res = await fetch(`${baseUrl}/create-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const status = await res.json()
    return status
}

const createVerificationCode = async (data) => {
    const res = await fetch(`${baseUrl}/create-verification-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const status = await res.json()
    return status
}

const verifyUser = async (data) => {
    const res = await fetch(`${baseUrl}/verify-user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const status = await res.json()
    return status
}

const generatePasswordResetCode = async (data) => {
    const res = await fetch(`${baseUrl}/generate-password-reset-code`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const status = await res.json()
    return status
}

const resetPassword = async (data) => {
    const res = await fetch(`${baseUrl}/reset-password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const status = await res.json()
    return status
}

const sendEmail = async (subject, message) => {
    const res = await fetch(`${baseUrl}/send-email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({subject: subject, message: message}),
    })
    const status = await res.json()
    return status
}

export {
    login,
    createUser,
    createVerificationCode,
    verifyUser,
    generatePasswordResetCode,
    resetPassword,
    sendEmail,
}