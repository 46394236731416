import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from './Button';
import Modal from './Modal';
import Logo from './Logo';

const Banner = () => {
  const location = useLocation()
  const navigate = useNavigate()

  const [confirmLogout, setConfirmLogout] = useState(false)

  const at = (path) => {
    return location.pathname == path
  }

  const logout = () => {
    localStorage.removeItem('email')
    window.location.reload()
  }

  return (
    <div className="fixed top-0 left-0 right-0 z-50 bg-white p-3 flex flex-row items-center h-24">
      <NavLink className="flex flex-row items-center" to="/">
        <div className='w-12 h-24 pt-2 drop-shadow-[0_2px_2px_rgba(0,0,0,0.5)]'>
          <Logo/>
        </div>
        <h1 className="text-4xl font-medium drop-shadow-[0_1px_1px_rgba(0,0,0,0.5)]">VoicingVault</h1>
      </NavLink>
      
      <div className="ml-auto flex items-stretch h-24">
        {/*
        <a target="_blank" className={"mr-20 text-2xl border-black rounded-t-lg h-24 flex items-center justify-center hover:text-orange-800"} href="https://docs.google.com/forms/d/e/1FAIpQLSej-8moXfBoF4j92mcdLMm0unq7_3Fk_Q1HIYAYkth-sLmS2g/viewform?usp=sf_link">
          Beta Testing Feedback
        </a>
        */}
        <NavLink className={"mr-20 text-2xl border-black rounded-t-lg h-24 flex items-center justify-center hover:text-orange-500" + (at("/player") ? ' border-b-4' : '' )} to="/player">
          Player
        </NavLink>
        <NavLink className={"mr-20 text-2xl border-black rounded-t-lg h-24 flex items-center justify-center hover:text-orange-500" + (at("/progress") ? ' border-b-4' : '' )} to="/progress">
          Progress
        </NavLink> 
        <NavLink className={"mr-20 text-2xl border-black rounded-t-lg h-24 flex items-center justify-center hover:text-orange-500" + (at("/blog") ? ' border-b-4' : '' )} to="/blog">
          Blog
        </NavLink> 
        { (!localStorage.getItem('email')) ? 
        <div className="mr-20 py-5 flex">
          <Button text={'Create Account'} onClick={() => navigate('/signup')} />
        </div>
        : 
        <p onClick={() => setConfirmLogout(true)} className="cursor-pointer mr-20 text-2xl border-black rounded-t-lg h-24 flex items-center justify-center hover:text-orange-500">Sign out</p>
        }
      </div>
      <Modal isOpen={confirmLogout} onClose={() => setConfirmLogout(false)} largeContent={false}>
        <div className="flex flex-col items-center">
          <p className="text-xl font-medium p-8">Are you sure you want to sign out?</p>
          <div className="flex flex-row">
            <div className="bg-red-500 rounded-lg hover:opacity-70 cursor-pointer flex justify-center items-center" onClick={logout}>
              <p className='py-2 px-4 font-medium text-3xl text-white'>Sign out</p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Banner;