import React, { useState } from 'react';
import TextInput from '../components/TextInput';
import {useNavigate} from 'react-router-dom'
import Button from '../components/Button';
import { resetPassword } from '../api/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const PasswordReset = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const validateCode = (code) => {
        return code.length == 9
    }

    const reset = async () => {
        const toastID = toast.loading('Resetting Password...', {
            position: 'top-center'
        })
        if (password !== confirmPassword) {
            toast.dismiss(toastID)
            toast.error('Passwords do not match', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        if (password.length < 8) {
            toast.dismiss(toastID)
            toast.error('Password must be at least 8 characters long', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        if (!validateCode(code)) {
            toast.dismiss(toastID)
            toast.error('Invalid code format', {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
            return
        }
        const status = await resetPassword({email: email.toLowerCase(), code: code, password: password})
        if (status == true) {
            // Dismiss toast
            toast.dismiss(toastID)

            toast.success('Password reset', {
                type: 'success',
                position: 'top-center',
                autoClose: 2000
            })
            
            navigate('/login')
        } else {
            toast.dismiss(toastID)
            toast.error(status.error, {
                type: 'error',
                position: 'top-center',
                autoClose: 4000
            })
        }

        
    }

    return (
        <div className="flex justify-center items-center py-16">
            <div className="shadow-lg px-12 py-8 flex flex-col">
                <div className="flex flex-row justify-center pb-4">
                    <p className="pt-2 font-medium text-2xl">Password Reset</p>
                </div>
                <div className="flex flex-col items-center">
                    <TextInput placeholder={'email'} text={email} setText={setEmail} isPassword={false}/>
                    <TextInput placeholder={'code'} text={code} setText={setCode} isPassword={false}/>
                    <TextInput placeholder={'password'} text={password} setText={setPassword} isPassword={true}/>
                    <TextInput placeholder={'confirm password'} text={confirmPassword} setText={setConfirmPassword} isPassword={true}/>
                    <div className="pt-4">
                        <Button small={true} onClick={reset} text={'Reset Password'} />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default PasswordReset